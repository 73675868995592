@use "src/shared/UI/styles/mixins";
@use "src/shared/UI/styles/variables";

$max-columns: 12;
$padding-default: calc(#{variables.$padding-md} / 2);
$padding-desktop: calc(#{variables.$padding-lg} / 2);
$columns: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12;

@function get-width($number) {
  @return calc(100% / (#{$max-columns} / #{$number}));
}

.col {
  flex-shrink: 0;
}

@each $column in $columns {
  .lg-#{$column} {
    @include mixins.desktop-screen-size {
      padding: $padding-desktop;
      width: get-width($column);
    }
  }

  .md-#{$column} {
    @include mixins.laptop-screen-size {
      padding: $padding-default;
      width: get-width($column);
    }
  }

  .sm-#{$column} {
    @include mixins.tablet-screen-size {
      padding: $padding-default;
      width: get-width($column);
    }
  }

  .xs-#{$column} {
    @include mixins.mobile-screen-size {
      padding: $padding-default;
      width: get-width($column);
    }
  }
}
