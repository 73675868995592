.container {
  flex: auto;
}

.text {
  max-width: 40rem;
  text-align: center;
}

.countdown {
  &_hidden {
    visibility: hidden;
  }
}