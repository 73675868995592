@use "src/shared/UI/styles/mixins";

.overlay {
  background: var(--color-white);
  padding: 2.4rem 2.6rem;
  @include mixins.box-shadow;
}

.icon {
  & path {
    stroke: var(--color-white);
    fill: var(--color-primary-dark-1) !important;
  }
}
