@use "src/shared/UI/styles/mixins";

.icon {
  margin-right: 1rem;
}

.button {
  position: relative;
  color: var(--color-secondary-dark-1);

  &:hover {
    color: var(--color-secondary-dark-1);
  }
}

.count {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  transform-origin: 100% 0%;
  min-width: 2rem;
  height: 2rem;
  padding: 0 0.6rem;
  color: var(--color-white);
  white-space: nowrap;
  text-align: center;
  background: var(--color-danger-dark-2);
  border-radius: 1rem;
  overflow: hidden;
  @include mixins.font-size-14;
}