@use "src/shared/UI/styles/mixins";
@use "src/shared/UI/styles/variables/layout";

$close-icon-size: 1.4rem;
$padding-size: layout.$padding-xl;
$sider-width: 35.7rem;

.sider {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: layout.$z-index-sider;
}

.body-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: $sider-width;
  height: 100vh;
  background-color: var(--color-white);
  flex: 0 0 $sider-width !important;
  transition: all .3s;
  z-index: layout.$z-index-sider;
  @include mixins.box-shadow-light-2;
  pointer-events: auto;
}

.mask {
  position: absolute;
  top: 0;
  right: $sider-width;
  bottom: 0;
  left: 0;
  z-index: layout.$z-index-sider;
  background: transparent;
  pointer-events: auto;
  margin-bottom: 0 !important;
}

.hidden {
  right: -$sider-width;
}

.controls {
  height: 6.9rem;
  padding: 1.9rem $padding-size;
  border-bottom: 1px solid var(--color-border);
}

.content {
  padding: $padding-size $padding-size 0 $padding-size;
  flex: auto;
  overflow-y: auto;
}

.actions {
  padding: $padding-size;
}

.close-icon {
  width: $close-icon-size;
  height: $close-icon-size;
  cursor: pointer;
}