$-icon-width: 2rem;
$-item-content-first-lvl-padding-y: 2.6rem;
$-item-content-first-lvl-padding-left: 2.8rem;
$-item-content-second-lvl-padding-y: 1.6rem;
$-item-content-text-margin-left: 2.9rem;

.item-icon {
  width: $-icon-width !important;
  height: 1.8rem;
}

.item-wrapper {
  height: unset !important;
  line-height: unset !important;
  max-width: 100% !important;
  margin: 0 !important;
  background: inherit !important;
}

.link-item__wrapper {
  &:hover {
    background: var(--color-secondary-light-2) !important;
  }

  & :global(.ant-menu-title-content),
  & :global(.ant-menu-title-content > :first-child) {
    height: unset !important;
    line-height: unset !important;
  }
}

:global(.main-sidebar) {
  & .link-item__content {
    &[class*="NavLink_isActive"] {
      background: var(--color-secondary-light-2) !important;
    }
  }
}

.link-item__content {
  width: 100%;
  height: 100%;
  display: inline-block;

  &[class*="TextMenuItem_text"] {
    display: block;
  }

  &[class*="NavLink_link"] :global(.ant-typography),
  &[class*="TextMenuItem_text"]:global(.ant-typography) {
    color: var(--color-text) !important;
  }

  & :global(.ant-typography),
  &:global(.ant-typography) {
    padding: 0;
    white-space: break-spaces;
    overflow-wrap: break-word;
    line-height: 2rem !important;
  }

  & :global(.ant-typography > :last-child),
  &:global(.ant-typography > :last-child) {
    min-width: 0;
  }

  & :global(.ant-typography > :only-child),
  &:global(.ant-typography > :only-child) {
    display: block;
    margin-left: calc($-icon-width + $-item-content-text-margin-left + 0.1rem) !important;
  }

  & :global(.ant-typography > :not(:first-child):last-child),
  &:global(.ant-typography > :not(:first-child):last-child) {
    display: inline-block;
    margin-left: $-item-content-text-margin-left !important;
  }

  &_first-lvl {
    padding: $-item-content-first-lvl-padding-y 2rem $-item-content-first-lvl-padding-y $-item-content-first-lvl-padding-left !important;
  }

  &_second-lvl {
    padding: $-item-content-second-lvl-padding-y 0 $-item-content-second-lvl-padding-y $-item-content-first-lvl-padding-left !important;
  }
}

.submenu-item__wrapper {
  margin: 0 !important;

  & :global(.ant-menu-submenu-title) {
    height: unset !important;
    line-height: unset !important;
    margin: 0;
  }

  & :global(.ant-menu-title-content) {
    height: 100%;
  }

  & :global(.ant-menu-submenu-arrow) {
    color: var(--color-text) !important;
  }
}

.submenu-item__content {
  height: 100%;
  display: flex;
  align-items: center;
  padding: $-item-content-first-lvl-padding-y 0 $-item-content-first-lvl-padding-y $-item-content-first-lvl-padding-left !important;
  line-height: 2rem !important;
  white-space: break-spaces;
  overflow-wrap: break-word;
  color: var(--color-text) !important;

  &:hover {
    background: var(--color-secondary-light-2) !important;
  }

  > :last-child {
    min-width: 0;
    margin-right: 3rem;
  }

  > :not(:first-child):last-child {
    display: inline-block;
    margin-left: 3rem !important;
  }
}

.group-item__wrapper {
  :global(.ant-menu-item-group-title) {
    padding: 0.8rem 0 0 calc(
      $-item-content-first-lvl-padding-left + $-icon-width + $-item-content-text-margin-left
    ) !important;
    white-space: break-spaces;
    overflow-wrap: break-word;
  }

  :global(.ant-menu-item-group-list) {
    padding: 0 !important;
  }
}
