@use "src/shared/UI/styles/variables";

.container {
  flex: auto;
}

.form-wrap {
  max-width: 85rem;
}

.agreement-wrapper {
  display: flex;
  align-items: flex-end;
}

.agreement {
  margin-bottom: variables.$margin-xl !important;
}

.login {
  position: absolute;
  top: 2rem;
  right: 2rem;
}