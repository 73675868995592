.button {
  width: 100%;
}

.upload {
  :global .ant-upload {
    display: block;
  }
}

.avatar {
  &-img, &-empty {
    width: 25.6rem;
    height: 25.6rem;
  }

  &-empty {
    border: 0.5rem solid var(--color-secondary-dark-1);

    :global path, :global circle {
      stroke-width: 0.5;
    }
  }
}
