.container {
  flex: auto;
}

.text {
  max-width: 40rem;
  text-align: center;
}

.icon {
  width: 5rem;
  height: 5rem;

  &_success {
    :global(path) {
      stroke: var(--color-success);
    }
  }
}