@use "src/shared/UI/styles/variables/layout";

$padding-size: layout.$padding-xl;

.table-filter {
  flex: auto;
}

.form {
  flex: auto;
  margin-bottom: 0 !important;
}

.actions {
  padding: $padding-size 0 $padding-size;
}