@use "src/shared/UI/styles/variables" as vars;

.main-part {
  margin-bottom: 1rem;

  &__avatar {
    width: 20rem;
    height: 20rem;
    object-fit: contain;
    object-position: top left;
    border: 3px solid var(--color-border);
    border-radius: vars.$border-radius-md;
  }

  &__info {
    padding-left: vars.$padding-md;
    width: calc(100% - 20rem);
  }
}

.description {
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.btn {
  &_loading {
    position: relative;

    > :first-child {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    > :not(:first-child) {
      margin-left: 0;
    }
  }
}