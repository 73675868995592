@use "src/shared/UI/styles/mixins";
@use "src/shared/UI/styles/variables";

$margin-default: calc(#{variables.$margin-md} / 2 * -1);
$margin-desktop: calc(#{variables.$margin-lg} / 2 * -1);

.grid {
  flex-grow: 1;
  margin: $margin-default;

  @include mixins.desktop-screen-size {
    margin: $margin-desktop;
  }
}