$displays: "flex", "inline-flex";
$directions: "row", "row-reverse", "column", "column-reverse";
$justifies: "flex-start", "flex-end", "center", "space-between", "space-around", "space-evenly", "stretch";
$aligns: "flex-start", "flex-end", "center", "stretch", "baseline";
$wraps: "nowrap", "wrap", "wrap-reverse";
$units: 0, 2, 4, 6, 8, 10, 12, 14, 16, 20, 30, 35, 40, 50, 60, 80, 100;

@function get-margin($unit) {
  @return calc(#{$unit}rem / 10);
}

@each $display in $displays {
  .flex--display--#{$display} {
    display: #{$display};
  }
}

@each $direction in $directions {
  @each $unit in $units {
    .flex--#{$direction}--#{$unit} {
      flex-direction: #{$direction};

      & > * {
        $margin: get-margin($unit);
        @if $direction == "row" { margin-right: $margin !important; }
        @if $direction == "row-reverse" { margin-left: $margin !important; }
        @if $direction == "column" { margin-bottom: $margin !important; }
        @if $direction == "column-reverse" { margin-top: $margin !important; }
      }

      > :last-child {
        @if $direction == "row" { margin-right: 0 !important; }
        @if $direction == "row-reverse" { margin-left: 0 !important; }
        @if $direction == "column" { margin-bottom: 0 !important; }
        @if $direction == "column-reverse" { margin-top: 0 !important; }
      }
    }
  }
}

@each $justify in $justifies {
  .flex--justify--#{$justify} {
    justify-content: #{$justify};
  }
}

@each $align in $aligns {
  .flex--align--#{$align} {
    align-items: #{$align};
  }
}

@each $wrap in $wraps {
  .flex--wrap--#{$wrap} {
    flex-wrap: #{$wrap};
  }
}